import {combineReducers} from 'redux'
import {environment} from '../../commons/reducers/environment'
import {experiments} from '../../commons/reducers/experiments'
import {siteSettings} from '../../commons/reducers/site-settings'
import {State} from '../types/state'
import {calendarLayout} from './calendar-layout'
import {component} from './component'
import {demoEvents} from './demo-events'
import {events} from './events'
import {layout} from './layout'
import {translation} from './translation'

export default combineReducers<State>({
  events,
  component,
  siteSettings,
  environment,
  translation,
  layout,
  calendarLayout,
  demoEvents,
  experiments
})
