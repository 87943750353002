import {IWidgetControllerConfig} from 'native-components-infra/dist/src/types/types'

export const createFedopsLogger = (controller: IWidgetControllerConfig) => {
  const {fedOpsLoggerFactory} = controller.platformAPIs
  let isFedopsReport = true

  const config = controller.appParams.baseUrls.iframeUrl
    ? 'wix-events-widget-editor'
    : {
        appId: controller.appParams.appDefinitionId,
        widgetId: controller.type
      }

  const fedopsLogger = fedOpsLoggerFactory.getLoggerForWidget(config)

  fedopsLogger.appLoadStarted()

  const onSSRPageReady = () => {
    fedopsLogger.appLoaded()
  }

  const onAppLoaded = () => {
    if (isFedopsReport) {
      fedopsLogger.appLoaded()
      isFedopsReport = false
    }
  }

  return {
    onSSRPageReady,
    onAppLoaded
  }
}
