export type UrlQueryParams = {[key: string]: string}

export const parseQueryParams = (query: string): UrlQueryParams => {
  try {
    query = query[0] === '?' ? query.substr(1) : query

    const params = query.split('&').reduce((res, param) => {
      const parts = param.split('=', 2)
      res[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1])
      return res
    }, {})

    return params
  } catch {
    return {}
  }
}

// TODO: externalize to commons
export const encodeQueryParams = (params: UrlQueryParams): string => {
  const encodedParams = Object.entries(params).map(param => `${param[0]}=${encodeURIComponent(param[1])}`)
  return `?${encodedParams.join('&')}`
}
