import {AnyAction} from 'redux'
import {GET_EVENTS, SET_TAB} from '../actions/events'
import {CANCEL_RSVP, DELETE_RSVP} from '../actions/rsvp'
import {DetailedEvent} from '../types/state'

const defaultState: DetailedEvent[] = []

export const eventsReducer = (state = defaultState, action: AnyAction): DetailedEvent[] => {
  switch (action.type) {
    case GET_EVENTS.SUCCESS:
      const {events, orders, rsvps} = action.payload
      return [...state, ...detailedEvents(events, orders, rsvps)]
    case CANCEL_RSVP.SUCCESS:
    case DELETE_RSVP.SUCCESS:
      return state.filter(event => event.event.id !== action.payload.eventId)
    case SET_TAB:
      return defaultState
    default:
      return state
  }
}

export const detailedEvents = (
  events: wix.events.Event[],
  orders: wix.events.ticketing.Order[],
  rsvps: wix.events.rsvp.Rsvp[]
): DetailedEvent[] =>
  events.map(event => ({
    event,
    orders: orders ? orders.filter(order => order.eventId === event.id) : [],
    rsvp: rsvps ? rsvps.find(rsvp => rsvp.eventId === event.id) : null
  }))
