import {GenericAPI} from '@wix/wix-events-commons-statics/dist/api/generic'
import {RsvpStatus} from '@wix/wix-events-commons-statics/dist/types/enums/rsvp'
import {GET_SITE_SETTINGS} from '../../commons/actions/site-settings'
import {SiteSettings} from '../../commons/types/state'
import {GET_COMPONENT_DATA, UPDATE_COMPONENT, UPDATE_COMPONENT_DRAFT} from '../actions/component'
import {GET_EVENTS} from '../actions/events'
import {CANCEL_RSVP, DELETE_RSVP} from '../actions/rsvp'

interface ApiParams {
  baseUrl: string
  instance: string
  compId: string
  locale: string
  viewMode: string
  XSRFTOKEN?: string
}

export class Api {
  api: GenericAPI
  instance: string
  compId: string
  locale: string
  viewMode: string
  registrar: any

  constructor(params: ApiParams) {
    this.validateParams(params)

    const {baseUrl, instance, compId, locale, viewMode, XSRFTOKEN = ''} = params

    this.api = new GenericAPI(
      baseUrl,
      () => [['Authorization', instance], ['Content-Type', 'application/json'], ['X-XSRF-TOKEN', XSRFTOKEN]],
      'same-origin'
    )

    this.instance = instance
    this.compId = compId
    this.viewMode = viewMode.toLowerCase()
    this.locale = locale

    this.registrar = {
      [GET_EVENTS.NAME]: this.getEvents,
      [DELETE_RSVP.NAME]: this.deleteRsvp,
      [CANCEL_RSVP.NAME]: this.cancelRsvp,
      [GET_SITE_SETTINGS.NAME]: this.getSiteSettings,
      [GET_COMPONENT_DATA.NAME]: this.getComponentData,
      [UPDATE_COMPONENT_DRAFT.NAME]: this.updateComponentDraft,
      [UPDATE_COMPONENT.NAME]: this.updateComponent
    }
  }

  validateParams(params: ApiParams) {
    const requiredParams = ['instance', 'baseUrl']

    requiredParams.forEach(param => {
      if (!params[param]) {
        throw new Error(`param ${param} is required`)
      }
    })
  }

  get(name: string) {
    const api = this.registrar[name]

    if (api) {
      return api
    }

    throw `API METHOD IS NOT REGISTERED ${name}`
  }

  getEvents = ({
    memberId,
    statuses,
    extended,
    offset = 0
  }: {
    memberId: string
    statuses: wix.events.EventStatus[]
    extended: boolean
    offset: number
  }): Promise<{
    events: wix.events.Event[]
    orders: wix.events.ticketing.Order[]
    rsvps: wix.events.rsvp.Rsvp[]
    total: number
  }> =>
    this.api.get(
      `/web/member-events?memberId=${memberId}&extended=${extended}&offset=${offset}${statuses
        .map(status => `&status=${status}`)
        .join('')}`
    )

  getSiteSettings = (): Promise<SiteSettings> => this.api.get(`/web/site-settings`)

  getComponentData = (): Promise<{component: wix.events.editor.WebComponent}> =>
    this.api.get(
      `/html/members-page-data?viewMode=${this.viewMode}&locale=${this.locale}&compId=${this.compId}&instance=${this.instance}`
    )

  updateComponentDraft = (component: wix.events.editor.WebComponentConfig) => {
    return this.api.put(`/web/component/${this.compId}/draft`, {component})
  }

  updateComponent = (component: wix.events.editor.WebComponentConfig) => {
    return this.api.put(`/web/component/${this.compId}`, {component})
  }

  deleteRsvp = ({eventId, rsvpId}: {eventId: string; rsvpId: string}) =>
    this.api.delete(`/web/events/${eventId}/rsvp/${rsvpId}`).then(() => ({eventId}))

  cancelRsvp = ({eventId, rsvpId}: {eventId: string; rsvpId: string}) =>
    this.api.put(`/web/events/${eventId}/rsvp/${rsvpId}/status`, {status: RsvpStatus.NO}).then(() => ({eventId}))
}
