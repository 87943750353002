import {Dispatch} from 'redux'

export const callAPI = ({SUCCESS, REQUEST, FAILURE, NAME}: ApiAction, ...args: any[]) => (
  dispatch: Dispatch,
  getState: Function,
  {serverApi}
) => {
  const api = serverApi.get(NAME)

  dispatch({type: REQUEST, payload: args})

  return api(...args)
    .then((payload: any) => {
      dispatch({type: SUCCESS, payload})
      return payload
    })
    .catch((error: any) => {
      dispatch({type: FAILURE})
      throw error
    })
}

export const createActions = (NAME: string): ApiAction => ({
  REQUEST: `${NAME}_REQUEST`,
  SUCCESS: `${NAME}_SUCCESS`,
  FAILURE: `${NAME}_FAILURE`,
  NAME
})

interface ApiAction {
  REQUEST: string
  SUCCESS: string
  FAILURE: string
  NAME: string
}
