import {GenericAPI} from '@wix/wix-events-commons-statics/dist/api/generic'
import {getLinguisticHeaderFromUrl} from '@wix/wix-events-commons-statics/dist/linguistic-header'
import {IWidgetControllerConfig} from 'native-components-infra/dist/src/types/types'
import {UPDATE_SITE_SETTINGS} from '../../commons/actions/site-settings'
import {SiteSettingsSettings} from '../../commons/types/state'
import {getServerBaseUrl} from '../../commons/utils/wix-code-api'
import {UPDATE_COMPONENT, UPDATE_COMPONENT_DRAFT} from '../actions/component'
import {CREATE_EVENT} from '../actions/event'

export class Api {
  api: GenericAPI
  registrar: any

  instance: string
  compId: string
  language: string
  viewMode: string
  petriOvr: string
  instanceId: string

  constructor(controller: IWidgetControllerConfig) {
    const {wixCodeApi, appParams, compId} = controller

    this.compId = compId
    this.instance = appParams.instance
    this.language = wixCodeApi.site.language
    this.viewMode = (wixCodeApi.window.viewMode === 'Site' ? 'Site' : 'Editor').toLowerCase() // preview loads wrong data for Editor
    this.petriOvr = wixCodeApi.location.query.petri_ovr
    this.instanceId = appParams.instanceId

    this.api = new GenericAPI(getServerBaseUrl(wixCodeApi), () => this.getHeaders(controller))

    this.registrar = {
      [UPDATE_COMPONENT.NAME]: this.updateComponent,
      [UPDATE_COMPONENT_DRAFT.NAME]: this.updateComponentDraft,
      [UPDATE_SITE_SETTINGS.NAME]: this.updateSiteSettings,
      [CREATE_EVENT.NAME]: this.createEvent
    }
  }

  get(name: string) {
    const api = this.registrar[name]

    if (api) {
      return api
    }

    throw `API METHOD IS NOT REGISTERED ${name}`
  }

  getAppData = () => {
    const path = `/html/widget-data?instance=${this.instance}&compId=${this.compId}&locale=${this.language}&viewMode=${this.viewMode}`

    return this.api.get(this.appendPetriOvr(path))
  }

  updateSiteSettings = (settings: SiteSettingsSettings) => {
    return this.api.put(`/web/site-settings`, {settings})
  }

  updateComponentDraft = (component: wix.events.editor.WebComponentConfig) => {
    return this.api.put(`/web/component/${this.compId}/draft`, {component})
  }

  updateComponent = (component: wix.events.editor.WebComponentConfig) => {
    return this.api.put(`/web/component/${this.compId}`, {component})
  }

  createEvent = (eventData: wix.events.EventData, ADI: boolean): Promise<{event: wix.events.Event; ADI: boolean}> => {
    return this.api.post('/adi/events', eventData).then(event => ({event, ADI}))
  }

  appendPetriOvr = (path: string) => (this.petriOvr ? `${path}&petri_ovr=${this.petriOvr}` : path)

  getLinguisticHeader = (controller: IWidgetControllerConfig) => {
    const {
      wixCodeApi: {
        window: {multilingual}
      }
    } = controller
    //@ts-ignore
    const enabled = multilingual.isEnabled

    if (enabled) {
      const language = multilingual.siteLanguages.find(lang => {
        return lang.languageCode === multilingual.currentLanguage
      })

      return `${language.languageCode}|${language.locale}|${language.isPrimaryLanguage}|${this.instanceId}`
    }
    if (controller.appParams.baseUrls.iframeUrl) {
      return getLinguisticHeaderFromUrl(controller.appParams.baseUrls.iframeUrl)
    }
  }

  getHeaders = multilingual => {
    const linguisticHeader = this.getLinguisticHeader(multilingual)

    const headers = [['Authorization', this.instance], ['Content-Type', 'application/json']]

    if (linguisticHeader) {
      headers.push(['x-wix-linguist', linguisticHeader])
    }

    return headers
  }
}
