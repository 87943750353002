import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'

export const getPageInfo = async (wixCodeApi: IWixAPI) => {
  try {
    return await wixCodeApi.site.getCurrentPageInfo()
  } catch (e) {
    console.log(e)
    return {isHomePage: false}
  }
}

export const isSSR = (wixCodeApi: IWixAPI) => wixCodeApi.window.rendering.env === 'backend'

export const getServerBaseUrl = (wixCodeApi: IWixAPI) => {
  if (isSSR(wixCodeApi)) {
    return `${parseBaseUrl(wixCodeApi).root}/_api/wix-one-events-server`
  } else {
    return '/_api/wix-one-events-server'
  }
}

export const parseBaseUrl = (wixCodeApi: IWixAPI) => {
  const baseUrl = wixCodeApi.location.baseUrl
  const baseUrlParts = baseUrl.split('/')
  return {
    root: baseUrlParts.slice(0, 3).join('/'),
    relative: baseUrlParts.slice(3).join('/')
  }
}
