import {IWixAPI} from 'native-components-infra/dist/es/src/types/types'
import {ModalActions} from '../types/state'
import {encodeQueryParams, UrlQueryParams} from './url'

interface OpenModalProps {
  wixCodeApi: IWixAPI
  baseUrl: string
  compId: string
  type: string
  params?: UrlQueryParams
}

export const openModal = ({wixCodeApi, compId, type, params}: OpenModalProps): Promise<{message: {reason: string}}> => {
  const queryParams = params ? encodeQueryParams(params) : ''
  return wixCodeApi.window.openPersistentPopup(
    // TODO: place server address into commons once commons are unlocked
    `https://apps.wix.com/events-server/html/modal/${type}${queryParams}`,
    {
      theme: 'BARE',
      width: '100%',
      height: '100%',
      position: {
        origin: 'FIXED',
        placement: 'CENTER',
        x: 0,
        y: 0
      }
    },
    compId
  )
}

interface OpenGeneralModalProps extends OpenModalProps {
  onConfirm: Function
  onClose?: Function
}

export const openDialogModal = ({onConfirm, onClose, ...rest}: OpenGeneralModalProps) =>
  openModal(rest).then(response => {
    if (response.message.reason === ModalActions.OK) {
      onConfirm && onConfirm()
    } else {
      onClose && onClose()
    }
  })
