import Experiments, {ExperimentsBag} from '@wix/wix-experiments'
import {EXPERIMENTS_SCOPE, MEMORY_CACHE_KEY} from '../config/constants'

import {IWidgetController, IWidgetControllerConfig, IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {isRtlLanguage} from '@wix/wix-events-commons-statics/dist/locale'
import {debounce} from 'lodash'
import {bindActionCreators, Store} from 'redux'
import {setBaseEnvironment} from '../../commons/actions/environment'
import {getSiteSettings} from '../../commons/actions/site-settings'
import {getLanguage} from '../../commons/selectors/environment'
import {getComponentData, updateComponent} from '../actions/component'
import {getEvents, setTab, shareEvent, toggleEventDetails} from '../actions/events'
import {internalNavigate, navigateToDetailsPage} from '../actions/navigation'
import {cancelRsvp} from '../actions/rsvp'
import {updateSettings} from '../actions/sdk'
import {resetToLiveView} from '../actions/view'
import reducers from '../reducers'
import {getMemberId} from '../selectors/user'
import {getFromMemoryCache, saveInMemoryCache} from '../services/store-cache'
import {Actions, MemberPageState} from '../types/state'
import {Api} from '../utils/api'
import {getMembersAPI} from '../utils/members-api'
import {createReduxStore} from '../utils/store'

export async function createMembersPageController(controller: IWidgetControllerConfig): Promise<IWidgetController> {
  const {appParams, setProps} = controller
  const experiments = await getExperimentsByScope(EXPERIMENTS_SCOPE)

  return {
    async pageReady() {
      try {
        const cashedState = await getCachedState(controller)
        const store = await createStore(controller, experiments, cashedState)
        const actions = exportedActions(store)
        const state = store.getState()

        setProps({
          state,
          actions,
          cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
          isRTL: isRtlLanguage(getLanguage(state))
        })

        if (!cashedState) {
          await Promise.all([actions.getEvents(), actions.getComponentData()])
        }
      } catch (e) {
        console.error(e)
        throw e
      }
    }
  }
}

const getCachedState = async (controller: IWidgetControllerConfig): Promise<MemberPageState | null> => {
  const state = getFromMemoryCache(controller.platformAPIs, MEMORY_CACHE_KEY) as MemberPageState
  if (state && getMemberId(state) === (await getSiteMemberId(controller))) {
    return state
  }
  return null
}

const createStore = async (
  controller: IWidgetControllerConfig,
  experiments: ExperimentsBag,
  cachedState: MemberPageState | null
) => {
  const serverApi = createServerApi(controller)
  const viewedSiteMemberId = await getSiteMemberId(controller)

  const initialData: Partial<MemberPageState> = {
    ...(cachedState || {}),
    experiments,
    user: {
      currentUserId: controller.wixCodeApi.user.currentUser.id,
      viewedSiteMemberId
    }
  }

  const store = await createReduxStore({
    initialData,
    extraArguments: {
      wixCodeApi: controller.wixCodeApi,
      serverApi,
      compId: controller.compId,
      baseUrl: controller.appParams.baseUrls.baseUrl,
      instance: controller.appParams.instance,
      pageUrl: await getPageUrl(controller)
    },
    reducers
  })

  await store.dispatch(getSiteSettings())
  await store.dispatch(<any>setBaseEnvironment())

  subscribeToStateChanges(controller, store)

  return store
}

const subscribeToStateChanges = (controller: IWidgetControllerConfig, store: Store) => {
  const onStateChange = () => {
    const state = store.getState()
    controller.setProps({state})
  }
  const saveInCache = debounce(
    () => {
      saveInMemoryCache(controller.platformAPIs, MEMORY_CACHE_KEY, store.getState())
    },
    200,
    {maxWait: 1000}
  )

  store.subscribe(onStateChange)
  store.subscribe(saveInCache)
}

const getSiteMemberId = async (controller: IWidgetControllerConfig) =>
  controller.appParams.baseUrls.siteMemberId || (await getViewedUserId(controller.wixCodeApi))

const getViewedUserId = async (wixCodeApi: IWixAPI) => {
  if (wixCodeApi.window.viewMode === 'Editor') {
    return ''
  }
  const membersApi = await getMembersAPI(wixCodeApi)
  return await membersApi.getViewedUser()
}

const createServerApi = (controller: IWidgetControllerConfig) =>
  new Api({
    baseUrl: controller.appParams.baseUrls.baseUrl,
    instance: controller.appParams.instance,
    locale: getLocale(controller),
    viewMode: controller.wixCodeApi.window.viewMode,
    compId: controller.compId
  })

const getLocale = ({wixCodeApi}: IWidgetControllerConfig): string => {
  return wixCodeApi.site.regionalSettings || 'en'
}

const getPageUrl = async ({wixCodeApi}: IWidgetControllerConfig): Promise<string> => {
  const pageUrl = await wixCodeApi.site.getSectionUrl({sectionId: 'events'})
  return (pageUrl && pageUrl.url) || null
}

const getExperimentsByScope = async (scope: string) => {
  const experiments = new Experiments({
    scope
  })
  await experiments.ready()
  return experiments.all()
}

const exportedActions = (store: Store): Actions =>
  bindActionCreators(
    {
      getComponentData,
      getEvents,
      toggleEventDetails,
      cancelRsvp,
      shareEvent,
      getSiteSettings,
      navigateToDetailsPage,
      internalNavigate,
      updateSettings,
      updateComponent,
      setTab,
      resetToLiveView
    },
    store.dispatch
  )
