import {AnyAction} from 'redux'
import {INTERNAL_NAVIGATE, UPDATE_NO_EVENT_URL} from '../actions/navigation'
import {Navigation, Routes} from '../types/state'

const defaultState: Navigation = {
  noUpcomingEventsLinkPageUrl: undefined,
  noUpcomingEventsLinkPageId: undefined,
  route: Routes.DEFAULT,
  routeParams: {}
}

export const navigation = (state = defaultState, action: AnyAction): Navigation => {
  switch (action.type) {
    case UPDATE_NO_EVENT_URL:
      return {
        ...state,
        noUpcomingEventsLinkPageUrl: action.payload.url,
        noUpcomingEventsLinkPageId: action.payload.pageId
      }
    case INTERNAL_NAVIGATE:
      return {
        ...state,
        route: action.payload.route,
        routeParams: action.payload.params
      }
    default:
      return state
  }
}
