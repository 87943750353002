import {Dispatch} from 'redux'
import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {openModal} from '../../commons/services/modal'
import {PAST_EVENT_STATUSES, UPCOMING_EVENT_STATUSES} from '../config/constants'
import {getMemberId, isOwnProfile} from '../selectors/user'
import {DetailedEvent, GetState, StoreExtraArgs, Tabs} from '../types/state'

export const TOGGLE_EVENT_DETAILS = 'TOGGLE_EVENT_DETAILS'
export const CLOSE_ALL_EVENTS = 'CLOSE_ALL_EVENTS'
export const GET_EVENTS = createActions('GET_EVENTS')
export const SET_TAB = 'SET_TAB'

export const getEvents = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const tab = state.tab
  const statuses = tab === Tabs.UPCOMING ? UPCOMING_EVENT_STATUSES : PAST_EVENT_STATUSES
  const offset = state.events.length
  const memberId = getMemberId(state)
  if (memberId) {
    dispatch(
      callAPI(GET_EVENTS, {
        memberId: getMemberId(state),
        statuses,
        extended: isOwnProfile(state),
        offset
      })
    )
  }
}

export const toggleEventDetails = (eventId: string) => ({
  type: TOGGLE_EVENT_DETAILS,
  payload: eventId
})

export const closeAllEvents = () => ({
  type: CLOSE_ALL_EVENTS
})

export const setTab = (tab: Tabs) => ({
  type: SET_TAB,
  payload: tab
})

export const shareEvent = (event: DetailedEvent) => (
  dispatch: Dispatch,
  getState: GetState,
  {wixCodeApi, compId, baseUrl, pageUrl}: StoreExtraArgs
) => {
  const eventUrl = `${pageUrl}/${event.event.slug}`
  openModal({
    type: 'share-event',
    wixCodeApi,
    baseUrl,
    compId,
    params: {eventUrl}
  })
}
