import {Action, Store} from 'redux'
import {BiParams} from '../bi'

interface CreateBiMiddlewareParams {
  bi: any
  eventMap: any
  biParams: BiParams
}

export const createBiMiddleware = ({bi, eventMap, biParams}: CreateBiMiddlewareParams) => {
  const PROD = biParams.appParams.baseUrls.staticsBaseUrl.includes('wix')

  const log = (action: Action, store: Store) => {
    const transformEvent = eventMap[action.type]

    if (PROD && transformEvent) {
      const biEvent = transformEvent(store.getState(), action, biParams)
      bi.log(biEvent, {endpoint: eventMap.endpoint})
    } else if (transformEvent) {
      console.log(transformEvent(store.getState(), action, biParams))
    }
  }

  return (store: Store) => (next: Function) => (action: Action) => {
    if (action) {
      log(action, store)
    }

    return next(action)
  }
}
