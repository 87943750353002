import {IWixAPI} from 'native-components-infra/dist/src/types/types'
import {Dispatch} from 'redux'
import {isDetailsPageEnabled} from '../../commons/selectors/site-settings'
import {navigateToDetailsPage} from '../../commons/services/navigation'
import {GetState, State} from '../types/state'

export const NAVIGATE_TO_PAGE = 'NAVIGATE_TO_PAGE'

export const navigateToPage = (event: wix.events.Event, state: State) => async (
  dispatch: Dispatch,
  getState: GetState,
  {wixCodeApi}: {wixCodeApi: IWixAPI}
) => {
  await navigateToDetailsPage(event, isDetailsPageEnabled(state.siteSettings), wixCodeApi)
  dispatch({type: NAVIGATE_TO_PAGE, payload: {event}})
}
