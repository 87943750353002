import {IPlatformAPI} from '@wix/native-components-infra/dist/src/types/types'

export function getFromMemoryCache(platformAPIs: IPlatformAPI, key: string) {
  try {
    const item = platformAPIs.storage.memory.getItem(key)
    if (item) {
      return JSON.parse(item)
    }
    return null
  } catch (error) {
    console.error(error)
    return null
  }
}

export function saveInMemoryCache(platformAPIs: IPlatformAPI, key: string, value) {
  try {
    return platformAPIs.storage.memory.setItem(key, JSON.stringify(value))
  } catch (error) {
    console.error(error)
  }
}
