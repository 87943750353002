import {Dispatch} from 'redux'
import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {isPreview} from '../../commons/selectors/environment'
import {isNoResponseEnabled} from '../../commons/selectors/event'
import {openDialogModal, openModal} from '../../commons/services/modal'
import {getEventId, getEventRsvp} from '../selectors/event'
import {getTitle} from '../selectors/event'
import {DetailedEvent, GetState, StoreExtraArgs} from '../types/state'

export const DELETE_RSVP = createActions('DELETE_RSVP')
export const CANCEL_RSVP = createActions('CANCEL_RSVP')

export const cancelRsvp = (event: DetailedEvent) => (
  dispatch: Dispatch,
  getState: GetState,
  {wixCodeApi, compId, baseUrl}: StoreExtraArgs
) => {
  const title = getTitle(event)
  const state = getState()
  if (isPreview(state)) {
    return openModal({
      type: 'no-cancel-rsvp-in-preview',
      wixCodeApi,
      baseUrl,
      compId
    })
  } else {
    return openDialogModal({
      type: 'member-cancel-rsvp',
      wixCodeApi,
      baseUrl,
      compId,
      params: {event: title},
      onConfirm: () => dispatch(onConfirmCancelRsvp(event) as any)
    })
  }
}

const onConfirmCancelRsvp = (event: DetailedEvent) => {
  if (isNoResponseEnabled(event.event)) {
    return callAPI(CANCEL_RSVP, {
      eventId: getEventId(event),
      rsvpId: getEventRsvp(event).id
    })
  } else {
    return callAPI(DELETE_RSVP, {
      eventId: getEventId(event),
      rsvpId: getEventRsvp(event).id
    })
  }
}
