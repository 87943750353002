import {AnyAction} from 'redux'
import {CLOSE_LIST_LAYOUT_ITEMS, OPEN_LIST_LAYOUT_ITEM} from '../actions/layout'
import {Layout} from '../types/state'

const defaultState: Layout = {
  listItemOpened: ''
}

export const layout = (state = defaultState, action: AnyAction): Layout => {
  switch (action.type) {
    case OPEN_LIST_LAYOUT_ITEM:
      return {
        ...state,
        listItemOpened: action.payload
      }
    case CLOSE_LIST_LAYOUT_ITEMS:
      return {
        ...state,
        listItemOpened: ''
      }
    default:
      return state
  }
}
