import * as DateFormatter from '@wix/wix-events-commons-statics/dist/date/formatter'
import {EventStatus} from '@wix/wix-events-commons-statics/dist/types/enums/events'
import {AnyAction} from 'redux'
import {UPDATE_COMPONENT_DRAFT} from '../actions/component'

const defaultState: wix.events.Event[] = []

export const events = (state = defaultState, action: AnyAction): wix.events.Event[] => {
  switch (action.type) {
    case UPDATE_COMPONENT_DRAFT.SUCCESS:
      return sortEvents(action.payload.component.events)
    default:
      return state
  }
}

export const sortEvents = (eventList: wix.events.Event[]) => {
  const upcomingEvents = getEventsByStatusSortedByDate(eventList, [EventStatus.SCHEDULED, EventStatus.STARTED])
  const pastEvents = getEventsByStatusSortedByDate(eventList, [EventStatus.ENDED])
  const canceledEvents = getEventsByStatusSortedByDate(eventList, [EventStatus.CANCELED])

  return [].concat(upcomingEvents, pastEvents, canceledEvents)
}

const getEventsByStatusSortedByDate = (
  eventList: wix.events.Event[],
  statuses: wix.events.EventStatus[]
): wix.events.Event[] =>
  eventList.filter((event: wix.events.Event) => statuses.indexOf(event.status) > -1).sort(eventComparator)

const eventComparator = (first: wix.events.Event, second: wix.events.Event) => {
  if (first.scheduling.config.scheduleTbd) {
    return second.scheduling.config.scheduleTbd ? DateFormatter.getDifference(second.modified, first.modified) : 1
  }

  if (second.scheduling.config.scheduleTbd) {
    return -1
  }

  const diff = DateFormatter.getDifference(first.scheduling.config.startDate, second.scheduling.config.startDate)

  if (diff === 0) {
    return DateFormatter.getDifference(second.modified, first.modified)
  }

  return diff
}
