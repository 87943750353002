import {IAppData, IPlatformAPI, IWixAPI} from 'native-components-infra/dist/src/types/types'
import {createBiMiddleware} from '../middlewares/bi'
import * as uouEvents from './uou-bi-events-map'
import * as usersEvents from './users-bi-events-map'

export interface BiParams {
  wixCodeApi: IWixAPI
  platformAPIs: IPlatformAPI
  appParams: IAppData
  compId: string
  user: {
    uid?: string
    aid?: string
  }
}

const createUserBiLogger = ({compId, platformAPIs, appParams}: BiParams) =>
  getBiLogger(platformAPIs)
    .updateDefaults({
      src: 68,
      msid: platformAPIs.bi.metaSiteId || '00000000-0000-0000-0000-000000000000',
      instance_id: appParams.instanceId,
      comp_id: compId
    })
    .logger()

const createUoUBiLogger = ({wixCodeApi, platformAPIs, compId, user}: BiParams) =>
  getBiLogger(platformAPIs)
    .updateDefaults({
      comp_id: compId,
      _viewMode: wixCodeApi.window.viewMode.toLowerCase()
    })
    .withUoUContext({
      msid: platformAPIs.bi.metaSiteId,
      visitorId: platformAPIs.bi.visitorId,
      siteMemberId: user.uid || ''
    })
    .logger()

const getBiLogger = (platformAPIs: IPlatformAPI) => {
  let logger = platformAPIs.biLoggerFactory()
  if (logger.factory) {
    logger = logger.factory()
  }
  return logger
}

export const createUsersBiMiddleware = (params: BiParams) =>
  createBiMiddleware({
    bi: createUserBiLogger(params),
    eventMap: usersEvents.eventMap,
    biParams: params
  })

export const createUouBiMiddleware = (params: BiParams) =>
  createBiMiddleware({
    bi: createUoUBiLogger(params),
    eventMap: uouEvents.eventMap,
    biParams: params
  })
