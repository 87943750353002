import {AnyAction} from 'redux'
import {GET_COMPONENT_DATA} from '../actions/component'
import {DetailedEvent} from '../types/state'
import {detailedEvents} from './events'

const defaultState: DetailedEvent[] = []

export const demoEvents = (state = defaultState, action: AnyAction): DetailedEvent[] => {
  switch (action.type) {
    case GET_COMPONENT_DATA.SUCCESS:
      if (action.payload.demoData) {
        const {events, orders, rsvps} = action.payload.demoData
        if (events) {
          return [...detailedEvents(events, orders, rsvps)]
        }
      }
      return state
    default:
      return state
  }
}
