import {AnyAction} from 'redux'
import {GET_EVENTS} from '../actions/events'
import {CANCEL_RSVP, DELETE_RSVP} from '../actions/rsvp'

const defaultState: number = 0

export const eventsTotal = (state = defaultState, action: AnyAction): number => {
  switch (action.type) {
    case GET_EVENTS.SUCCESS:
      return action.payload.total
    case CANCEL_RSVP.SUCCESS:
    case DELETE_RSVP.SUCCESS:
      return state > 0 ? state - 1 : state
    default:
      return state
  }
}
